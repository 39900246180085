import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)

  const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
  const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
  const [EditScreenShot, setEditScreenShot] = React.useState("")
  const [EditStartDate, setEditStartDate] = React.useState("")
  const [EditEndDate, setEditEndDate] = React.useState("")
  const [EditAmountPaid, setEditAmountPaid] = React.useState("")
  const [EditRemarks1, setEditRemarks1] = React.useState("")
  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)
  const [ExamVerificationList, setExamVerificationList] = React.useState(FetchData.meta.exam_verification_list.split(","))
  const [reloadE, setReloadE] = React.useState(false)
  const [DeviceID, setDeviceID] = React.useState(FetchData.meta.device_id)
  var ExamVerificationList2 = FetchData.meta.exam_verification_list.split(",");
  // const [Name, setName] = React.useState(FetchData.title.rendered)
  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": UserRole,
        "user_profile_picture": ProfilePicture,
        "user_phone_number": PhoneNumber,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": `${userName}@saekkidlapanbelas.com`,
        // "user_email": userEmail,
        "device_id": DeviceID,
        "exam_verification_list": ExamVerificationList2.toString()
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
          window.location.reload(false);
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": `${userName}@saekkidlapanbelas.com`,
        // "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const dateChangePressed = (verifyTime) => {
    const date = new Date();

    const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

    const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
    const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
    setEditStartDate(`${yearToday}-${monthToday}-${day}`)
    setEditEndDate(`${year}-${month}-${day}`)

  }

  const CustomButton2 = ({ DataValue }) => {
    const [find, setFind] = React.useState(ExamVerificationList2.includes(DataValue))
    return (
      <div
        onClick={() => {

          if (find) {
            for (let i in ExamVerificationList2)
              if (ExamVerificationList2[i] == DataValue)
                ExamVerificationList2.splice(i, 1)
            setFind(false)
          }
          else {
            ExamVerificationList2.push(DataValue)
            setFind(true)
          }
          console.log(ExamVerificationList2)
        }}
      >

        {
          find ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
        }
        &nbsp; {DataValue}
      </div >
    )
  }

  return (
    <div>{console.log(Login)}
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information </center></h4>
          <table>
            <tbody>
              <tr>
                {/* <td className="col-3">Student Full Name </td> */}
                <td className="col-3">Nama Lengkap </td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => {
                        if (event.target.value.match(/^[a-z0-9-]+$/) != null)
                          setUserName(event.target.value)
                        else if (event.target.value == "")
                          setUserName(event.target.value)
                      }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                {/* <td className="col-3">Student Role</td> */}
                <td className="col-3">Status</td>
                <td className="col-6">
                  {/* <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} /> */}
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setUserRole("subscriber")
                    }}
                  >
                    <div className={UserRole == "subscriber" ? "active-option" : "passive-option"}>
                      {/* Semua */}
                      Siswa
                    </div>
                  </button>
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setUserRole("student")
                    }}
                  >
                    <div className={UserRole == "student" ? "active-option" : "passive-option"}>
                      {/* Siswa */}
                      LPK Admin
                    </div>
                  </button>
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setUserRole("author")
                    }}
                  >
                    <div className={UserRole == "author" ? "active-option" : "passive-option"}>
                      Web Admin
                    </div>
                  </button>
                  {/* <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} /> */}
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Verification For</td>
                <td className="col-6">
                  <CustomButton2 DataValue={"UBT"} />
                 <CustomButton2 DataValue={"CBT"} />
                  <CustomButton2 DataValue={"TextBook"} />
                  <CustomButton2 DataValue={"Video"} />
                  <CustomButton2 DataValue={"LJDExam"} />
                  <CustomButton2 DataValue={"LJDPembahasan"} /> 
                </td>
              </tr> */}
              <tr>
                {/* <td className="col-3">Profile Picture</td> */}
                <td className="col-3">Foto Profil</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                {/* <td className="col-3">Device ID</td> */}
                <td className="col-3">ID Perangkat</td>
                <td className="col-6" onClick={() => { setDeviceID("") }}>
                  {/* {DeviceID} {DeviceID !== "" && "Reset ID"} */}
                  {DeviceID} {DeviceID !== "" && "Hapus ID"}
                </td>
              </tr>

              {/* <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          {/* Change Password */}
                          Ubah Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                {/* Update Student */}
                Update Data Siswa
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Verification History</center></h4>
          <table>
            <tbody>
              <tr>
                <td >Screenshot</td>
                <td >Start Date</td>
                <td >End Date</td>
                <td >Amount Paid</td>
                <td ></td>
              </tr>
              {Object.values(userVerificationHistory).map((item, index) => (
                <tr key={index}>
                  <td >
                    {item.verify_screenshot !== "" &&
                      <a href={item.verify_screenshot} target="_blank">
                        <img src={item.verify_screenshot} style={{ width: "20px", height: "20px" }} />
                      </a>
                    }
                  </td>
                  <td >{item.verify_start_date}</td>
                  <td >{item.verify_end_date}</td>
                  <td >{item.amount_paid}</td>
                  <td
                    onClick={() => {
                      setUserVerificationHistoryModal(true)
                      setEditScreenShot(item.verify_screenshot)
                      setEditStartDate(item.verify_start_date)
                      setEditEndDate(item.verify_end_date)
                      setEditAmountPaid(item.amount_paid)
                      setEditRemarks1(item.verification_remarks_1)
                      setEditRemarks2(item.verification_remarks_2)
                      setEditKey(Object.keys(userVerificationHistory)[index])
                    }}
                  >Edit</td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}
                  onClick={() => {
                    setUserVerificationHistoryModal(true)
                    setEditScreenShot("")
                    setEditStartDate("")
                    setEditEndDate("")
                    setEditAmountPaid("")
                    setEditRemarks1("")
                    setEditRemarks2("")
                    setEditKey(`item-${Object.keys(userVerificationHistory).length}`)
                  }}
                >
                  <center><div style={{ width: "100px" }} className="sign-in-button-4"> New</div></center>
                </td>
              </tr>
            </tbody>
          </table>
        </div >
      </div >
      */}

      {userVerificationHistoryModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setUserVerificationHistoryModal(false)
                    setEditKey("")
                  }}
                >X</div>
                <table>
                  <tr>
                    <td className="col-4"><b>SN : </b></td>
                    <td className="col-7">
                      {Number(editKey.replace("item-", "")) + 1}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>ScreenShot : </b></td>
                    <td className="col-7">
                      <ImageUpload
                        ImageUrl={EditScreenShot}
                        setImageUrl={setEditScreenShot}
                      />
                      {EditScreenShot !== "" &&
                        <img src={EditScreenShot} style={{ height: "200px", width: "200px", objectFit: "contain" }} />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Subscription : </b></td>
                    <td>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("3 months")
                          dateChangePressed(3)
                        }}
                      >
                        <div className={subscriptionTime == "3 months" ? "active-option" : "passive-option"}>
                          3 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("6 months")
                          dateChangePressed(6)
                        }}
                      >
                        <div className={subscriptionTime == "6 months" ? "active-option" : "passive-option"}>
                          6 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("1 year")
                          dateChangePressed(12)
                        }}
                      >
                        <div className={subscriptionTime == "1 year" ? "active-option" : "passive-option"}>
                          12 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("Custom")
                        }}
                      >
                        <div className={subscriptionTime == "Custom" ? "active-option" : "passive-option"}>
                          Custom
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Start Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditStartDate}
                          onChange={(event) => {
                            setEditStartDate(event.target.value)
                          }}
                          placeholder="Start Date"
                        />
                        :
                        <div>
                          {EditStartDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>End Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditEndDate}
                          onChange={(event) => { setEditEndDate(event.target.value) }}
                          placeholder="End Date"
                        />
                        :
                        <div>
                          {EditEndDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Paid Amount : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditAmountPaid}
                        onChange={(event) => { setEditAmountPaid(event.target.value) }}
                        placeholder="Amount Paid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Remarks : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditRemarks1}
                        onChange={(event) => { setEditRemarks1(event.target.value) }}
                        placeholder="Remarks"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <center>
                        <div style={{ width: "30%" }} className="sign-in-button-1"
                          onClick={() => {
                            setUserVerificationHistory({
                              ...userVerificationHistory,
                              [editKey]: {
                                'verify_screenshot': EditScreenShot,
                                'verify_start_date': EditStartDate,
                                'verify_end_date': EditEndDate,
                                'amount_paid': EditAmountPaid,
                                'verification_remarks_1': EditRemarks1,
                                'verification_remarks_2': EditRemarks2,
                              }
                            }
                            )
                            setUserVerificationHistoryModal(false)
                          }}
                        >Done</div>
                      </center>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}