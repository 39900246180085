import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import store from './redux/store';

import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Path/Header';
import NotFound from './Path/NotFound';
import SideBar from './Path/SideBar';

import AllBooks from './Path/Admin/Book';
import NewBook from './Path/Admin/Book/BookAddNew';
import BookDetails from './Path/Admin/Book/BookDetails';
import { slugs } from './Constant/slugs';
import AllExam from './Path/Admin/Exam';
import AllResults from './Path/Admin/Results';
import ExamDetails from './Path/Admin/Exam/ExamDetails';
import ResultDetails from './Path/Admin/Results/ResultsDetails';
import NewExam from './Path/Admin/Exam/ExamAddNew';
import AllStudents from './Path/Admin/Students';
import StudentDetails from './Path/Admin/Students/StudentDetails';
import NewStudent from './Path/Admin/Students/StudentAddNew';
import AllPaidExamList from './Path/Student/Exam';
import AllDemoExamList from './Path/Student/DemoExam';
import AllChpaterExamList from './Path/Student/ChapterWiseExam';
import AllDailyExamList from './Path/Student/DailyExam';
import LoginScreen from './Path/SignIn';
import StartExam from './Path/Student/StartExam';
import StartDemoExam from './Path/Student/StartDemoExam';
import ExamResult from './Path/Student/ExamResult';
import StartDailyExam from './Path/Student/StartDailyExam';
import DailyExamResult from './Path/Student/ExamResult/DailyExam';
import ExamAllResult from './Path/Student/AllResults';
import DailyExamAllResult from './Path/Student/AllResults/DailyResults';
import StartChapterExam from './Path/Student/ChapterWiseExam/StartExam';
import Profile from './Path/Profile';
import AllBatch from './Path/Admin/Batch';
import BatchDetails from './Path/Admin/Batch/BatchDetails';
import NewBatch from './Path/Admin/Batch/BatchAddNew';
import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
import AllCBTExam from './Path/Admin/CBTExam';
import AllTextBookExam from './Path/Admin/TextBookExam';
import NewExamCBT from './Path/Admin/CBTExam/ExamAddNewCBT';
import NewExamText from './Path/Admin/TextBookExam/ExamAddNewText';
import ExamDetailsText from './Path/Admin/TextBookExam/ExamDetailsText';
import AllVideos from './Path/Admin/Videos';
import VideoDetails from './Path/Admin/Videos/VideoDetails';
import NewVideo from './Path/Admin/Videos/VideoAddNew';
import AllLJDExam from './Path/Admin/LJDExam';
import LJDExamDetails from './Path/Admin/LJDExam/ExamDetails';
import NewLJDExam from './Path/Admin/LJDExam/ExamAddNew';
import AllLJDVideos from './Path/Admin/LJD/Videos';
import VideoLJDDetails from './Path/Admin/LJD/Videos/VideoDetails';
import NewLJDVideo from './Path/Admin/LJD/Videos/VideoAddNew';
import AllLJDResults from './Path/Admin/LJD/Result';
import ExamResult2 from './Path/Student/ExamResult/ExamResult2';
import AllCBTExamList from './Path/Student/Exam/CBT';
import AllTextBookExamList from './Path/Student/Exam/TextBook';
import AllBooksList from './Path/Student/Books';
import Home from './Path/Student/Home';


export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <AppMain />
        </Router>
      </Provider>
    );
  }
}

function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const myState = useSelector((state) => state.LocationState);
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()

  const [menuExpand, setMenuExpand] = React.useState(false)
  useEffect(() => {
    dispatch(getUser())
    setLoginState(Login.isLoading)
  }, [])
  return (
    <div className="overflow-x-hidden" >
      {Login.token == null ?
        // <LoginScreen />
        <Switch>
          <Route exact path="/"><LoginScreen /></Route>
          <Route path={slugs.exam_result}><ExamResult2 /></Route>
          <Route path="*"><NotFound /></Route>
          <Route exact path="/*"><LoginScreen /></Route>
        </Switch>
        :
        <div className="row" >
          {!location.pathname.includes("start") &&
            <div className="sidebar-width-expand">
              <div className={"sidebar-design"}>
                <SideBar menuExpand={menuExpand} />
              </div>
            </div>
          }

          {menuExpand &&
            <div className="sidebar-small-screen">
              <div className={"sidebar-design"} >
                <SideBar setMenuExpand={setMenuExpand} />
              </div>
            </div>
          }

          <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#ddd" }}>
            <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
            <div>
              {Login.userRole == "author" &&
                <Switch>
                  <Route exact path="/"><AllExam /></Route>

                  <Route exact path="/profile"><Profile /></Route>
                  <Route path={slugs.all_books}><AllBooks /></Route>
                  <Route path={slugs.all_videos}><AllVideos /></Route>
                  <Route path={slugs.all_exam}><AllExam /></Route>
                  <Route path={slugs.all_cbt_exam}><AllCBTExam /></Route>
                  <Route path={slugs.all_text_book_exam}><AllTextBookExam /></Route>
                  <Route path={slugs.all_ljd_exam}><AllLJDExam /></Route>
                  <Route path={slugs.all_ljd_videos}><AllLJDVideos /></Route>
                  <Route path={slugs.all_results}><AllResults /></Route>
                  <Route path={slugs.all_students}><AllStudents /></Route>

                  <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
                  <Route path={`${slugs.video_details}:slug`}><VideoDetails /></Route>
                  <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
                  <Route path={`${slugs.text_exam_details}:slug`}><ExamDetailsText /></Route>
                  <Route path={`${slugs.ljd_exam_details}:slug`}><LJDExamDetails /></Route>
                  <Route path={`${slugs.video_ljd_details}:slug`}><VideoLJDDetails /></Route>
                  <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
                  <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>

                  <Route path={slugs.new_book}><NewBook /></Route>
                  <Route path={slugs.new_video}><NewVideo /></Route>
                  <Route path={slugs.new_exam}><NewExam /></Route>
                  <Route path={slugs.new_cbt_exam}><NewExamCBT /></Route>
                  <Route path={slugs.new_ljd_exam}><NewLJDExam /></Route>
                  <Route path={slugs.new_ljd_video}><NewLJDVideo /></Route>
                  <Route path={slugs.new_text_exam}><NewExamText /></Route>
                  <Route path={slugs.new_student}><NewStudent /></Route>


                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>
                  <Route path={slugs.cbtexam}><AllCBTExamList /></Route>
                  <Route path={slugs.textbookexam}><AllTextBookExamList /></Route>
                  <Route path={slugs.book_list_user}><AllBooksList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
                  <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>

                  <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>
                  <Route path={slugs.ljd_exam_result_list}><AllLJDResults /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
              {(Login.userRole == "student" || Login.userRole == "subscriber") &&
                <Switch>
                  {/* <Route exact path="/"><AllPaidExamList /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>
                  <Route path={slugs.cbtexam}><AllCBTExamList /></Route>
                  <Route path={slugs.textbookexam}><AllTextBookExamList /></Route>
                  <Route path={slugs.book_list_user}><AllBooksList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
                  <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route> */}

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
            </div>
          </div>
        </div>
      }

    </div >
  )
}
